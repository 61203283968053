import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import {
  DeliverablesOverallFeedbackInterface,
  PerformanceKPISection,
  PerformanceReviewCounts,
  PerformanceReviewInterface,
  PerformanceReviewStats,
  ReviewCategory,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { api, apiWithoutHandling, handleError } from '@src/api/index'
import { API, EntityTypes } from '@src/constants/api'
import axios, { AxiosPromise, CancelTokenSource } from 'axios'
import {
  CommitteeResultInterface,
  ExpectedSkillRatingInterface,
  JiraIssueInterface,
  JiraIssuesInterface,
  ProbationCheckpoints,
  ProbationDeliverablesInterface,
  ProbationGoalInterface,
  ProbationKPIRecommendationInterface,
  ProbationManagersGrade,
  ProbationRecommendationInterface,
  ProbationResults,
} from '@src/interfaces/probationReview'
import { ScorecardInterface } from '@src/interfaces/scorecard'
import { useFetch } from '@src/utils/reactQuery'
import { kpisRequests } from '@src/api/kpis'
import { tableInitialFiltersRouter } from '@src/pages/Forms/AssignKPIs/routers'
import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  DeliverableInterface,
  DeliverableInterfaceRequest,
} from '@src/interfaces/deliverables'
import { getCommentsAPI } from '@src/api/comments'
import { getReviewCycleIdWithoutPrefix } from '@src/utils/reviewCycles'
import { useGetReviewCycleByOffset } from './reviewCycles'

export const probationReviewRequests: RequestInterfaceNew<ReviewScorecardInterface> = {
  get: async ({ id, employeeId }) => {
    return api.get(
      `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceReviews/${id}?category=${ReviewCategory.Probation}`,
      {},
      'v2',
    )
  },
  update: async (data, { id }) =>
    api.put(
      `${API.EMPLOYEES}/${data.reviewed_employee!.id}${
        API.PERFORMANCE
      }/performanceReviews/${id}`,
      data,
      {},
      'v2',
    ),
  submit: async data =>
    api.post(
      `${API.EMPLOYEES}/${data.reviewed_employee?.id}${API.PERFORMANCE}/cycles/${data.cycle?.id}/performanceReviews`,
      {
        category: ReviewCategory.Probation,
      },
      {},
      'v2',
    ),
}

export const probationReviewLayoutRequests: RequestInterfaceNew<ReviewScorecardInterface> =
  {
    get: async ({ id, employeeId }) => {
      return api.get(
        `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceReviews/${id}`,
        {
          params: {
            category: ReviewCategory.Probation,
          },
        },
        'v2',
      )
    },
    update: async (data, { id }) =>
      apiWithoutHandling.put(
        `${API.EMPLOYEES}/${data.reviewed_employee!.id}${
          API.PERFORMANCE
        }/performanceReviews/${id}`,
        data,
        {},
        'v2',
      ),
    submit: async data =>
      apiWithoutHandling.post(
        `${API.EMPLOYEES}/${data.reviewed_employee?.id}${API.PERFORMANCE}/cycles/${data.cycle?.id}/performanceReviews`,
        {
          category: ReviewCategory.Probation,
        },
        {},
        'v2',
      ),
  }

export const useGetProbationCheckpoints = (
  employeeId: number | null,
  cycleId: string | null,
) =>
  useFetch<ProbationCheckpoints>(
    employeeId && cycleId
      ? `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/probationCheckpoints`
      : null,
    'v2',
  )

export const getProbationJiraTickets = (
  category: ReviewCategory,
  id: number,
  checkpointNumber?: number | string,
  reviewers?: string,
): AxiosPromise<ProbationDeliverablesInterface> =>
  api.get(
    `${API.EMPLOYEES}/${id}${API.PERFORMANCE}/functional/tickets/${category}`,
    {
      params: {
        [`${category}_checkpoint_number`]: checkpointNumber,
        scorecards_by: reviewers,
      },
    },
    'v2',
  )

export const useGetProbationTickets = (
  id: number | null,
  filters: FilterByInterface[],
) => {
  const context = useFetch<{ results: DeliverableInterface[] }>(
    id ? `${API.EMPLOYEES}/${id}${API.PERFORMANCE}/goals` : null,
    'v2',
    {
      params: filterSortPageIntoQuery(undefined, filters, undefined),
    },
  )

  return { ...context, data: context.data?.results }
}

export const getProbationTickets = (id: number, filters: FilterByInterface[]) =>
  api.get<DeliverableInterfaceRequest>(
    `${API.EMPLOYEES}/${id}${API.PERFORMANCE}/goals`,
    {
      params: filterSortPageIntoQuery(undefined, filters, undefined),
    },
    'v2',
  )

export const getExpectedRatings = (
  id: number,
  cycleId: string,
): AxiosPromise<ExpectedSkillRatingInterface[]> =>
  api.get(
    `${API.EMPLOYEES}/${id}${API.PERFORMANCE}/cycles/${cycleId}/expectedSkillsRatings`,
    {},
    'v2',
  )

export const getProbationRecommendationStructure = (
  employeeId: number,
  cycleId: string,
): AxiosPromise<ProbationRecommendationInterface> =>
  api.get(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/probationRecommendations/structure`,
    {},
    'v2',
  )

export const getProbationRecommendations = (
  employeeId: number,
  cycleId: string,
): AxiosPromise<GetRequestInterface<ProbationRecommendationInterface>> =>
  api.get(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/probationRecommendations`,
    {},
    'v2',
  )

export const useGetProbationRecommendations = (
  employeeId: number | null,
  cycleId: string | number | null,
) =>
  useFetch<GetRequestInterface<ProbationRecommendationInterface>>(
    employeeId === null || cycleId === null
      ? null
      : `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/probationRecommendations`,
    'v2',
  )

export const probationRecommendationRequests: RequestInterfaceNew<ProbationRecommendationInterface> =
  {
    get: async ({ employeeId, cycleId, id }) =>
      api.get(
        `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/probationRecommendations/${id}`,
        {},
        'v2',
      ),
    update: async (_, { employeeId, cycleId, id }, data) =>
      api.put(
        `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/probationRecommendations/${id}`,
        data,
        {},
        'v2',
      ),
    submit: async (data, { employeeId, cycleId }) =>
      api.post(
        `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/probationRecommendations`,
        data,
        {},
        'v2',
      ),
  }

export const committeeResultRequests: RequestInterfaceNew<CommitteeResultInterface> = {
  get: async ({ employeeId, id, cycleId }) =>
    api.get(
      `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/probationDecisions/${id}`,
      {},
      'v2',
    ),
  update: async (_, { employeeId, id, cycleId }, data) =>
    api.put(
      `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/probationDecisions/${id}`,
      {
        ...data,
        cycle: {
          id: cycleId,
        },
        end_of_probation_date_time:
          data?.result === ProbationResults.Extend
            ? data?.end_of_probation_date_time
            : null,
      },
      {},
      'v2',
    ),
  submit: async (data, { employeeId, cycleId }) =>
    api.post(
      `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/probationDecisions`,
      {
        ...data,
        cycle: {
          id: cycleId,
        },
      },
      {},
      'v2',
    ),
}

export const useGetCommitteeResult = (
  category: ReviewCategory,
  employeeId?: number,
  id?: string | number,
  cycleId?: string | number,
) =>
  useFetch<CommitteeResultInterface>(
    !employeeId || !id || !cycleId
      ? null
      : `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/${
          category === ReviewCategory.PIP_V2 ? 'pipDecisions' : 'probationDecisions'
        }/${id}`,
    'v2',
  )

export const putDeliverablesFeedback = (
  employeeId: number,
  id: number,
  data: DeliverablesOverallFeedbackInterface,
  category: ReviewCategory,
) => {
  return api.put<ScorecardInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceReviews/${id}/deliverablesOverallFeedback`,
    data,
    { params: { category: category === ReviewCategory.PIP_V2 ? 'pip' : 'probation' } },
    'v2',
  )
}

export const searchJiraTickets = () => {
  let searchJiraTokenSource: CancelTokenSource

  return async (
    term: string,
  ): Promise<{ issues?: JiraIssueInterface[]; cancelPrevQuery?: boolean }> => {
    try {
      if (searchJiraTokenSource !== undefined) {
        searchJiraTokenSource.cancel('Operation canceled due to new request.')
      }

      searchJiraTokenSource = axios.CancelToken.source()

      const resp = await apiWithoutHandling.get<JiraIssuesInterface>(
        `/jira/search`,
        {
          params: {
            term,
          },
          cancelToken: searchJiraTokenSource.token,
        },
        'v1',
      )

      return { issues: resp.data.issues }
    } catch (error) {
      if (axios.isCancel(error)) {
        return { cancelPrevQuery: true }
      }

      handleError(error)
      return error
    }
  }
}

export const getProbationGoals = (employeeId: number, cycleId: string) =>
  api.get<GetRequestInterface<ProbationGoalInterface>>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/probationGoals`,
    undefined,
    'v2',
  )

export const getProbationKPIs = (
  employeeId: number,
  cycleId: string,
  goalsEnabled: boolean,
) => {
  return kpisRequests.getItems({
    filters: tableInitialFiltersRouter(
      String(employeeId),
      EntityTypes.employee,
      undefined,
      cycleId,
      goalsEnabled,
    ),
  })
}

export const addProbationGoal = (
  employeeId: number,
  data: ProbationGoalInterface,
  cycleId: string,
) =>
  api.post<ProbationGoalInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/probationGoals`,
    data,
    undefined,
    'v2',
  )

export const deleteProbationGoal = (
  employeeId: number,
  goalId: number,
  cycleId: string,
) =>
  api.delete(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/probationGoals/${goalId}`,
    undefined,
    'v2',
  )

export const getProbationManagersGrade = (
  employeeId: number,
  cycleId: string,
): AxiosPromise<ProbationManagersGrade> =>
  api.get(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/probationDecisions/calculatedManagersGrade`,
    undefined,
    'v2',
  )

export const getProbationKPIRecommendation = (
  employeeId: number,
  reviewId: number,
  data: PerformanceKPISection,
): AxiosPromise<ProbationKPIRecommendationInterface> =>
  api.post(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceReviews/${reviewId}/kpiDeliverablesOverallRating?category=probation`,
    data,
    undefined,
    'v2',
  )

export const approveProbationGoals = (
  employeeId: number,
  cycleId: string,
  checkpointId: number,
) =>
  api.put(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/probationCheckpoints/${checkpointId}/approve`,
    {},
    undefined,
    'v2',
  )

export const probationCommentsApi = (cycleId?: number | string) =>
  getCommentsAPI({
    baseUrl: `${API.PROBATION_COMMENTS}/${getReviewCycleIdWithoutPrefix(
      cycleId,
    )}/comments`,
    apiVersion: 'v2',
  })

export const getAllProbationReviews = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<PerformanceReviewInterface>
> =>
  api.get(
    `${API.PERFORMANCE}/employeeReviews`,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  )

export const getSubmittableProbationReviews = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<PerformanceReviewInterface>
> =>
  api
    .get<GetRequestInterface<PerformanceReviewInterface>>(
      `${API.PERFORMANCE}/employeeReviews`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    )
    .then(response => ({
      ...response,
      data: {
        ...response.data,
        results: response.data.results.filter(
          review => !!review.performance_checkpoint?.can_submit,
        ),
      },
    }))

export const getAllProbationReviewsStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface) =>
  api.get<PerformanceReviewStats>(
    `${API.PERFORMANCE}/employeeReviews/stats`,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  )

export const useReviewStats = (filters?: FilterByInterface[]) => {
  const cycle = filters?.find(({ columnName }) => columnName === 'cycle__id')?.filters
  const { data: currentCycle } = useGetReviewCycleByOffset(0)
  return useFetch<PerformanceReviewCounts>(
    `${API.PERFORMANCE}/reviews/counts`,
    'v2',
    {
      params: filterSortPageIntoQuery(undefined, [
        {
          columnName: 'cycle__id',
          filters:
            cycle ||
            (currentCycle && [{ id: currentCycle.id, name: String(currentCycle.id) }]) ||
            [],
        },
      ]),
    },
    true,
  )
}
